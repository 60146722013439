// src/components/Navbar/index.tsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./navBar.module.css"; // Asegúrate de que la importación de estilos coincida con el nombre del archivo
import Logo from "../../assets/ImageHome/LogoHide-81.png";
import LogoX from "../../assets/ImageHome/Logo_X_1-8.svg";
import LogoWhatsapp from "../../assets/ImageHome/Logo_Whatsapp-8.svg";
import LogoLinkedin from "../../assets/ImageHome/Logo_Linkedin_1-8.svg";
import IconHamburger from "../../assets/icons/hamburger.svg"; // Asegúrate de tener un icono de hamburguesa

const Navbar: React.FC = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const handleToggleNav = () => {
    setIsNavExpanded(!isNavExpanded);
    console.log("Navbar Expanded:", !isNavExpanded); // Esto te mostrará el estado actualizado en la consola
  };

  return (
    <header className={styles.navbar}>
      <div className={styles.navbarContent}>
        <div className={styles.navbarContentContainer}>
          <div className={styles.navbar__logo}>
            <Link to="/">
              <img src={Logo} alt="DATA SAFE Logo" />
            </Link>
          </div>
          <div className={styles.navbar__items}>

            <button
              className={styles.hamburger}
              onClick={handleToggleNav}
              aria-expanded={isNavExpanded}
            >
              <img
                src={IconHamburger}
                alt="Menu"
                className={styles.hamburgerIcon}
              />
            </button>
            <nav
              className={`${styles.navbar__navigation} ${isNavExpanded ? styles.navbar__expanded : ""
                }`}
            >
              <ul className={styles.navbar__list}>
                <li className={styles.navbar__item}>
                  <Link
                    to="/AboutUs"
                    className={styles.navbar__link}
                    onClick={() => setIsNavExpanded(false)}
                  >
                    NOSOTROS
                  </Link>
                </li>
                <li className={styles.navbar__item}>
                  <Link
                    to="/Service"
                    className={styles.navbar__link}
                    onClick={() => setIsNavExpanded(false)}
                  >
                    SERVICIOS
                  </Link>
                </li>
                {/* <li className={styles.navbar__item}>
                <Link
                  to="/Technology"
                  className={styles.navbar__link}
                  onClick={() => setIsNavExpanded(false)}
                >
                  TECNOLOGÍAS
                </Link>
              </li> */}
                <li className={styles.navbar__item}>
                  <Link
                    to="/Contact"
                    className={styles.navbar__link__contact}
                    onClick={() => setIsNavExpanded(false)}
                  >
                    CONTACTO
                  </Link>
                </li>
              </ul>
            </nav>
            <div className={styles.navbar__social}>
              <a
                href="https://x.com/datasafe_us?s=21&t=dMFJ_ZGr2YBwiF3T-luSiQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LogoX}
                  alt="X Social Media"
                  className={styles.navbar__icon}
                />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LogoLinkedin}
                  alt="LinkedIn"
                  className={styles.navbar__icon}
                />
              </a>
              <a
                href="https://whatsapp.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LogoWhatsapp}
                  alt="WhatsApp"
                  className={styles.navbar__icon}
                />
              </a>
            </div>
          </div>
        </div>

      </div>
    </header>
  );
};

export default Navbar;
