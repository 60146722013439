// src/pages/Home/index.tsx
import React from "react";
import styles from "./Technology.module.css";
import Rectangle25 from "../../assets/imageTechnology/Rectangle25.png";
import Rectangle26 from "../../assets/imageTechnology/Rectangle26.png";
import Rectangle13 from "../../assets/imageTechnology/Rectangle13.png";
import { Helmet } from "react-helmet-async"; // Importa Helmet para gestionar los meta tags

const Technology: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Tecnologías | DATASAFE</title>
        <meta name="description" content="Descubre cómo DATASAFE puede proteger tus activos digitales con servicios avanzados de ciberseguridad y protección de datos. Asegura tu empresa con nuestras soluciones integrales." />
        <link rel="canonical" href="https://datasafesas.com/Technology" />
        <meta name="keywords" content="seguridad de la información, protección de datos, ciberseguridad, servicios tecnológicos, defensa digital" />
        <meta name="author" content="DATASAFE" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Añade más meta etiquetas según sea necesario */}
      </Helmet>

      <div className={styles.carouselContainer} style={{ backgroundImage: `url(${Rectangle13})` }}>
        {/* Contenido opcional del carrusel */}
      </div>
        <div style={{ backgroundColor: "#F6F5F5", padding: "20px" }}>
      <div className={styles.containerContentPage}>
          <div className={styles.highlightSection}>
            <div className={styles.highlightContent}>
              <h2 className={styles.highlightTitle}>
                ¡Protege lo más valioso con nuestros servicios!
              </h2>
              <div className={styles.highlightDescription}>
                En un mundo digital en constante evolución, la seguridad de la
                información y la protección de datos son cruciales para el éxito
                de tu empresa. En DATASAFE, ofrecemos soluciones integrales
                de ciberseguridad diseñadas para proteger tus activos más valiosos
                contra amenazas y vulnerabilidades.
              </div>
            </div>
            <div className={styles.highlightContentImage}>
              <img
                src={Rectangle26}
                alt="Highlight"
                className={styles.highlightImage}
              />
            </div>
          </div>
        </div>
        <div style={{ padding: "20px" }}>
      <div className={styles.containerContentPage}>

          <div className={styles.highlightSection}>
            <img
              src={Rectangle25}
              alt="Highlight"
              className={styles.highlightImage}
            />
            <div className={styles.highlightContent} style={{ alignItems: "center" }}>
              <div className={styles.highlightDescription}>
                No dejes tu seguridad al azar. Contáctanos hoy y descubre cómo
                podemos ayudarte a fortalecer tu defensa y proteger tu futuro
                digital.
              </div>
              <h2 className={styles.highlightTitle} style={{ textAlign: "center" }}>
                ¡Asegura tu empresa con los mejores!
              </h2>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Technology;
