// src/components/FloatingButtonWhatsApp/index.tsx
import React from 'react';
import styles from './FloatingButtonWhatsApp.module.css';
import LogoWhatsApp from '../../assets/FloatingButtons/Whatsapp_logo.png'; // Asegúrate de tener la imagen del ícono de WhatsApp

const FloatingButtonWhatsApp = () => {
  return (
    <div className={styles.container}>
      <a href="https://wa.me/message/URHDIJXUMWXCC1" target="_blank" rel="noopener noreferrer">
        <img src={LogoWhatsApp} alt="WhatsApp" className={styles.buttonIcon} />
      </a>
    </div>
  );
};

export default FloatingButtonWhatsApp;
