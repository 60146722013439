// src/components/Carousel/index.tsx
import React from "react";
import styles from "./carousel.module.css";
import backgroundImage from "../../assets/ImageHome/Rectangle7.1.png"; // Asegúrate de que la ruta sea correcta
import { Helmet } from "react-helmet-async"; // Importa Helmet para gestionar los meta tags
import { Link } from "react-router-dom";

const Carousel: React.FC = () => {
  return (
    <div className={styles.carouselContainer} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Helmet>
      <title>Inicio | DATASAFE</title>
        <meta name="description" content="¡La seguridad de tu organización no tiene precio! Refuerza tus cimientos digitales y protege tu esencia empresarial." />
        <link rel="canonical" href="https://datasafesas.com" />
        <meta name="keywords" content="seguridad informática, protección empresarial, seguridad organizacional" />
        <meta name="author" content="DATASAFE" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Añade más meta etiquetas según sea necesario */}
      </Helmet>

      <div className={styles.carouselContent}>
        <h2 className={styles.carouselTitle}>
          ¡LA SEGURIDAD <br /> DE TU ORGANIZACIÓN NO TIENE PRECIO!
        </h2>
        {/* Ejemplo de subtítulo y botones */}
        {/* <p className={styles.carouselSubtitle}>
          Refuerza tus cimientos digitales, protege tu esencia empresarial. La
          seguridad informática no es solo una inversión, es un escudo que
          preserva la integridad de tu organización.
        </p>
        <div className={styles.carouselButtons}>
          <Link to="/Service">
            <button className={styles.buttonPrimary}>Botón 1</button>
          </Link>
          <button className={styles.buttonSecondary}>Botón 2</button>
        </div> */}
      </div>

      {/* Ejemplo de sección de imagen */}
      {/* <div className={styles.imageSection}>
        <img src={sideImage} alt="Descripción de la imagen" />
      </div> */}
    </div>
  );
};

export default Carousel;
