// src/pages/Service/index.tsx
import React, { useEffect } from "react";
import styles from "./Service.module.css";
import backgroundImage from "../../assets/imageService/Rectangle13.png";
import serviceSelectOne from "../../assets/imageService/Rectangle 31.png";
import serviceSelectTwo from "../../assets/imageService/Rectangle32.png";
import serviceSelectThree from "../../assets/imageService/18.Ciberseguridad Ofensive.jpeg";
import serviceSelectFour from "../../assets/imageService/Rectangle33.png";

import ServiceSection from "../../components/ServiceSection/ServiceSection"; // Importa el componente
import { Helmet } from "react-helmet-async"; // Importa Helmet para gestionar los meta tags

// Datos de los servicios
const government = [
  {
    title: "Implementación del SGSI.",
    description:
      "Apoyamos a las organizaciones en la implementación del SGSI, incluyendo la definición de políticas y procedimientos, la identificación y evaluación de riesgos, y la implementación de controles de seguridad.",
    imageUrl: serviceSelectOne,
  },
  {
    title: "Auditoría y evaluación de la conformidad.",
    description: "Realizamos auditorías internas y/o externas para evaluar el cumplimiento de tu organización con los requisitos de la norma ISO 27001 y de esta forma poder proporcionar las recomendaciones necesarias para que realices mejoras.",
    imageUrl: serviceSelectOne,
  },
  {
    title: "Formación y concienciación.",
    description: "Capacitamos a los empleados sobre los principios de seguridad de la información, las mejores prácticas y los requisitos de la norma ISO 27001.",
    imageUrl: serviceSelectOne,
  },
  {
    title: "Mantenimiento y mejora continua.",
    description: "Brindamos asesoramiento continuo para el mantenimiento y mejora del SGSI, incluyendo la revisión periódica, la actualización de políticas y procedimientos, y la adaptación a los cambios en el entorno de seguridad de la información.",
    imageUrl: serviceSelectOne,
  },
];
const cybersecurity = [
  {
    title: "Servicios y controles de acceso.",
    description: `
      
        <li style={{ text-align: unset; }} >Adquisición e instalación de certificados SSL/TLS y controles criptográficos.</li>
        <li style={{ text-align: unset; }} >Configuración y administración de servidores.</li>
        <li style={{ text-align: unset; }} >Renovación y mantenimiento de certificados.</li>
        <li style={{ text-align: unset; }} >Soporte técnico y resolución de problemas.</li>
      
    `,
    imageUrl: serviceSelectTwo,
  },
  {
    title: "Firewalls UTM.",
    description: `
      
        <li style:>Evaluación de necesidades y diseño de soluciones.</li>
        <li style={{ text-align: unset; }} >Implementación y configuración.</li>
        <li style={{ text-align: unset; }} >Monitoreo y gestión.</li>
        <li style={{ text-align: unset; }} >Actualizaciones y parches.</li>
        <li style={{ text-align: unset; }} >Generación de informes y análisis.</li>
        <li style={{ text-align: unset; }} >Soporte técnico y resolución de problemas.</li>
      
    `,
    imageUrl: serviceSelectTwo,
  },
  {
    title: "Endpoint Security.",
    description: `
      
        <li style={{ text-align: unset; }} >Implementación y configuración.</li>
        <li style={{ text-align: unset; }} >Monitoreo (Observabilidad) y detección de amenazas.</li>
        <li style={{ text-align: unset; }} >Escaneo y eliminación de malware.</li>
        <li style={{ text-align: unset; }} >Actualizaciones de seguridad.</li>
        <li style={{ text-align: unset; }} >Gestión centralizada.</li>
        <li style={{ text-align: unset; }} >Investigación y respuesta a incidentes.</li>
      
    `,
    imageUrl: serviceSelectTwo,
  },
  {
    title: "Controladores de dominio.",
    description: `
      
        <li style={{ text-align: unset; }} >Implementación y configuración.</li>
        <li style={{ text-align: unset; }} >Migración y actualización.</li>
        <li style={{ text-align: unset; }} >Administración y mantenimiento.</li>
        <li style={{ text-align: unset; }} >Seguridad y respaldo.</li>
        <li style={{ text-align: unset; }} >Gestión centralizada.</li>
        <li style={{ text-align: unset; }} >Monitoreo y resolución de problemas.</li>
      
    `,
    imageUrl: serviceSelectTwo,
  },
  {
    title: "Hardening infraestructura.",
    description: `
      
        <li style={{ text-align: unset; }} >Instalación, configuración y administración de Servidores.</li>
        <li style={{ text-align: unset; }} >Clientes y Periféricos de Red.</li>
        <li style={{ text-align: unset; }} >Instalación, configuración y administración de Dispositivos.</li>
        <li style={{ text-align: unset; }} >Activos de Interconexión.</li>
      
    `,
    imageUrl: serviceSelectTwo,
  },
  {
    title: "Continuidad / BCP.",
    description: `
      
        <li style={{ text-align: unset; }} >Análisis de impacto empresarial.</li>
        <li style={{ text-align: unset; }} >Evaluación y gestión de riesgos.</li>
        <li style={{ text-align: unset; }} >Desarrollo de estrategias de continuidad.</li>
        <li style={{ text-align: unset; }} >Implementación y prueba del plan.</li>
        <li style={{ text-align: unset; }} >Mantenimiento y actualización.</li>
      
    `,
    imageUrl: serviceSelectTwo,
  },
];
const ofensive = [
  {
    title: "Detección y análisis de vulnerabilidades.",
    description: `
      
        <li style={{ text-align: unset; }} >Evaluación de vulnerabilidades.</li>
        <li style={{ text-align: unset; }} >Análisis de código de fuente.</li>
        <li style={{ text-align: unset; }} >Evaluación de configuración.</li>
        <li style={{ text-align: unset; }} >Análisis de seguridad de aplicaciones web.</li>
        <li style={{ text-align: unset; }} >Informes y recomendaciones. </li>
      
    `,
    imageUrl: serviceSelectThree,
  },
  {
    title: "Hacking Ético (Pentesting).",
    description: `
    
      <li style={{ text-align: unset; }} >Pruebas de penetración de red.</li>
      <li style={{ text-align: unset; }} >Pruebas de penetración de aplicaciones.</li>
      <li style={{ text-align: unset; }} >Pruebas de penetración inalámbricas.</li>
      <li style={{ text-align: unset; }} >Informes y recomendaciones.</li>
    
  `,
  imageUrl: serviceSelectThree,
  },
  {
    title: "Ingeniería social.",
    description: `
    
      <li style={{ text-align: unset; }} >Evaluaciones de ingeniería social.</li>
      <li style={{ text-align: unset; }} >Concientización y capacitación.</li>
      <li style={{ text-align: unset; }} >Evaluación de phishing.</li>
      <li style={{ text-align: unset; }} >Evaluaciones de ingeniería social física.</li>
      <li style={{ text-align: unset; }} >Consultoría y asesoramiento.</li>
    
  `,
  imageUrl: serviceSelectThree,
  },
  {
    title: "Incidentes de seguridad de la información.",
    description: `
    
      <li style={{ text-align: unset; }} >Respuesta a incidentes.</li>
      <li style={{ text-align: unset; }} >Monitoreo y detección de incidentes.</li>
      <li style={{ text-align: unset; }} >Investigación forense digital.</li>
      <li style={{ text-align: unset; }} >Recuperación de incidentes.</li>
      <li style={{ text-align: unset; }} >Evaluación de incidentes y mejora continua.</li>
    
  `,
  imageUrl: serviceSelectThree,
  },
];
const firewalls = [
  {
    title: "Protección de datos personales ",
    description: `
        <li style={{ textAlign: unset; }} >Evaluación y cumplimiento normativo.</li>
        <li style={{ text-align: unset; }} >Diseño de políticas y procedimientos.</li>
        <li style={{ text-align: unset; }} >Implementación de medidas de seguridad.</li>
        <li style={{ text-align: unset; }} >Evaluación de riesgos.</li>
        <li style={{ text-align: unset; }} >Capacitacion y concientizacion.</li>
        <li style={{ text-align: unset; }} >Gestión de incidentes de seguridad de datos.</li>
    `,
    imageUrl: serviceSelectFour,
  },
  
  
];

const Service: React.FC = () => {
  useEffect(() => {
    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <div>
      <Helmet>
        <title>Servicios | DATASAFE</title>
        <meta name="description" content="Descubre nuestros servicios en gobierno TI basado en ISO27000, ciberseguridad defensiva y firewalls UTM. Protege tu información con DATASAFE." />
        <link rel="canonical" href="https://datasafesas.com/Service" />
        <meta name="keywords" content="gobierno TI, ISO 27001, ciberseguridad, firewalls UTM, DATASAFE" />
        <meta name="author" content="DATASAFE" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Añade más meta etiquetas según sea necesario */}
      </Helmet>

      <div className={styles.carouselContainer} style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className={styles.carouselContent}>
          {/* <h4 className={styles.carouselTitle}>
            Anticípate a los ciberdelincuentes, fortalece tus activos digitales
            a través de nuestros servicios
          </h4>
          <div className={styles.carouselButtons}>
            <Link to="/DescriptionService">
              <button className={styles.buttonSecondary}>Botón 2</button>
            </Link>
          </div> */}
        </div>
      </div>

      {/* Agregado el componente ServiceDisplay para mostrar los servicios */}
      
      <div>
        <ServiceSection
          id="gobierno-sgsi"
          services={government}
          mainTitle="GOBIERNO TI BASADO EN ISO27000 "
          backgroundColor="#ffff"
        />
        <ServiceSection
          id="ciberseguridad"
          services={cybersecurity}
          mainTitle="CIBERSEGURIDAD DEFENSIVE"
          backgroundColor="#000"
        />
        <ServiceSection
          id="ofensive"
          services={ofensive}
          mainTitle="CIBERSEGURIDAD OFENSIVE"
          backgroundColor="#ffff"
        />
        <ServiceSection
          id="firewalls"
          services={firewalls}
          mainTitle="PROTECCION DE DATOS"
          backgroundColor="#000"
        />
      </div>
    </div>
  );
};

export default Service;
