import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/navBar"; // Importa el Navbar
import Footer from "./components/Footer/footer"; // Importa el Footer
import Home from "./pages/Home/home";
import AboutUs from "./pages/AboutUs/AboutUs";
import Service from "./pages/Service/Service";
import Technology from "./pages/Technology/Technology";
import Contact from "./pages/Contact/Contact";
import DescriptionService from "./pages/DescriptionService/DescriptionService";
import FloatingButtons from "./components/FloatingButtons/FloatingButtons";
import "./App.css"; // Asegúrate de que la ruta al archivo CSS es correcta
import FloatingButtonWhatsApp from "./components/FloatingWhatsap/FloatingButtonWhatsApp";

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <div className="containerNavbar">
          <Navbar /> {/* Agrega el Navbar aquí */}
        </div>
        <div className="containerContent">
          <FloatingButtons /> {/* Agrega el Navbar aquí */}
          <FloatingButtonWhatsApp /> {/* Agrega el Navbar aquí */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Service" element={<Service />} />
            <Route path="/Technology" element={<Technology />} />
            <Route path="/Contact" element={<Contact />} />
            <Route
              path="/DescriptionService"
              element={<DescriptionService />}
            />
            {/* Define más rutas según sea necesario */}
          </Routes>
        </div>
        <Footer /> {/* Agrega el Footer aquí */}
      </div>
    </Router>
  );
};

export default App;
