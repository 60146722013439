// src/components/ContactForm/index.tsx
import React from "react";
import styles from "./Contact.module.css";
import { Helmet } from "react-helmet-async"; // Importa Helmet para gestionar los meta tags
import { Link } from "react-router-dom";

import Logo from "../../assets/imageContact/logocontact-81.svg";
import LogoX from "../../assets/ImageHome/Logo_X_1-8.svg";
import LogoWhatsapp from "../../assets/ImageHome/Logo_Whatsapp-8.svg";
import LogoLinkedin from "../../assets/ImageHome/Logo_Linkedin_1-8.svg";

const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>Contacto | DATASAFE</title>
        <meta
          name="description"
          content="Contacta con DATASAFE para resolver tus necesidades de seguridad digital. Nuestro equipo está listo para ayudarte con soluciones eficaces y personalizadas."
        />
        <link rel="canonical" href="https://datasafesas.com/Contact" />
        <meta
          name="keywords"
          content="contacto DATASAFE, seguridad digital, ciberseguridad, protección de datos, consultoría ISO 27001"
        />
        <meta name="author" content="DATASAFE" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Añade más meta etiquetas según sea necesario */}
      </Helmet>
<div>
      <div
            className={styles.containerContentPage}
          >
      <div className={styles.container}>
          <div className={styles.header}>
            <h2>
              ¿Será un placer trabajar juntos, cuéntanos cuál es tu necesidad?
            </h2>
            {/* <p style={{color:'#686868', textAlign:'justify'}}>
          Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industria.
          </p> */}
            <div className={styles.navbar__social}>
              <a
                href="https://x.com/datasafe_us?s=21&t=dMFJ_ZGr2YBwiF3T-luSiQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LogoX}
                  alt="X Social Media"
                  className={styles.navbar__icon}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/datasafeus/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LogoLinkedin}
                  alt="LinkedIn"
                  className={styles.navbar__icon}
                />
              </a>
              <a
                href="https://wa.me/message/URHDIJXUMWXCC1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LogoWhatsapp}
                  alt="WhatsApp"
                  className={styles.navbar__icon}
                />
              </a>
            </div>
            <div className={styles.navbar__logo}>
              <Link to="/">
                <img src={Logo} alt="DATA SAFE Logo" />
              </Link>
            </div>
          </div>
        <form className={styles.form}>
          <input type="text" placeholder="Nombres" />
          <input type="text" placeholder="Apellidos" />
          <textarea placeholder="Mensajes"></textarea>
          <button type="submit">Enviar</button>
        </form>
        </div>

      </div>
    </div>
    </div>
  );
};

export default Contact;
