// src/components/Footer/index.tsx
import React from 'react';
import styles from './footer.module.css';
import iconX from '../../assets/ImageHome/Logo_X_1-8.svg'; // Reemplaza con la ruta real a tu imagen
import iconLinkedIn from '../../assets/ImageHome/Logo_Linkedin_1-8.svg'; // Reemplaza con la ruta real a tu imagen
import iconPhone from '../../assets/ImageHome/Logo_Whatsapp-8.svg'; // Reemplaza con la ruta real a tu imagen

const Footer: React.FC = () => {
  return (
    <footer style={{color: "#000", backgroundColor:"#000"}}>
      <div  className={styles.footerContainer}>
      <span className={styles.footerText}>
      Todos los derechos reservados por DATASAFE US
      </span>
      <div className={styles.socialIcons}>
        <a className={styles.socialIcon} href="https://x.com/datasafe_us?s=21&t=dMFJ_ZGr2YBwiF3T-luSiQ" target="_blank" rel="noreferrer">
          <img src={iconX} alt="X"  />
        </a>
        <a className={styles.socialIcon} href="https://www.linkedin.com/company/datasafeus" target="_blank" rel="noreferrer">
          <img src={iconLinkedIn} alt="LinkedIn"  />
        </a>
        <a className={styles.socialIcon} href="https://wa.me/message/URHDIJXUMWXCC1" target="_blank" rel="noreferrer">
          <img src={iconPhone} alt="Phone"  />
        </a>
      </div>
      </div>
    </footer>
  );
};

export default Footer;
