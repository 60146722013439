// src/pages/Home/index.tsx
import React from "react";
import styles from "./AboutUs.module.css";
import sliderTwo from "../../assets/imageContact/slider-86.png";
import sliderThree from "../../assets/imageContact/logocontact.png";
import sliderOne from "../../assets/imageContact/slider-8.1.png";
import { Helmet } from "react-helmet-async"; // Importa Helmet para gestionar los meta tags

const AboutUs: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Nosotros | DATASAFE</title>
        <meta
          name="description"
          content="DATASAFE es una empresa especializada en seguridad de la información, ciberseguridad, redes y protección de datos. Conoce más sobre nuestro equipo y nuestros servicios."
        />
        <link rel="canonical" href="https://datasafesas.com/AboutUs" />
        <meta
          name="keywords"
          content="seguridad de la información, ciberseguridad, protección de datos, redes, DATASAFE"
        />
        <meta name="author" content="DATASAFE" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Añade más meta etiquetas según sea necesario */}
      </Helmet>

      <div>
        <div className={styles.highlightSectionContainer}>
          <div className={styles.containerContentPage}>
            <div className={styles.highlightSection}>
              <div className={styles.highlightContent}>
                <h2 className={styles.highlightTitle}>¿Quiénes Somos?</h2>
                <p className={styles.highlightDescription}>
                  Somos un equipo de profesionales multidisciplinarios
                  apasionados por la ciberseguridad y la privacidad de datos
                  comprometidos con mantener los activos digitales seguros en un
                  mundo cada vez más conectado. Somos una empresa enfocada en la
                  prestación de servicios de Seguridad Informática, redes y
                  telecomunicaciones, Ciberseguridad y protección de datos,
                  teniendo siempre como prioridad la confidencialidad,
                  integridad y disponibilidad de la información de nuestros
                  clientes, garantizando siempre la calidad y el resultado de
                  nuestros servicios.
                </p>
              </div>
              <img src={sliderOne} alt="Highlight" style={{ width: "450px" }} />
            </div>
          </div>
        </div>
        <div className={styles.highlightSectionContainer}>
          <div className={styles.containerContentPage}>
            <div className={styles.highlightSection}>
              <img
                src={sliderTwo}
                alt="Highlight"
                className={styles.highlightImage}
              />
              <div className={styles.highlightContent}>
                <p className={styles.highlightDescription}>
                  DATASAFE nace por la necesidad global de las organizaciones de
                  contar con servicios en el campo de la seguridad de la
                  Información, ciberseguridad, redes y protección de datos y
                  como un sueño de varios profesionales apasionados por ayudar a
                  las organizaciones en la constante lucha por proteger sus
                  activos más preciados.
                  <br />
                  En DATASAFE somos creadores de barreras invulnerables para
                  salvaguardar la esencia de la información. La seguridad para
                  nosotros no es simplemente una capa; es el tejido mismo de
                  nuestra experiencia.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.highlightSectionContainer}>
          <div className={styles.containerContentPage}>
            <div className={styles.highlightSection}>
              <div className={styles.highlightContent}>
                <p className={styles.highlightDescription}>
                  Consideramos la innovación y el conocimiento como la esencia
                  misma de nuestro ADN. Las soluciones que ofrecemos no solo
                  responden a desafíos presentes, sino que anticipan los del
                  mañana. Somos pioneros en la creación de un mañana digital más
                  seguro.
                  <br />
                  <br />
                  Desde la gestión de datos hasta la transmisión de información,
                  para nosotros cada interacción es una sincronización entre la
                  seguridad y la eficiencia.
                </p>
              </div>
              <img
                src={sliderThree}
                alt="Highlight"
                className={styles.highlightImage}
              />
            </div>
          </div>
        </div>
        <div className={styles.highlightSectionContainer}>
          <div className={styles.containerContentPage}>
            <div className={styles.highlightSection}>
              <img
                src={sliderTwo}
                alt="Highlight"
                className={styles.highlightImage}
              />
              <div className={styles.highlightContent}>
                <p className={styles.highlightDescription}>
                  Conectamos más que dispositivos; somos maestros constructores
                  de puentes digitales, eliminando distancias y acercando
                  oportunidades.
                  <br />
                  <br />
                  Nuestro equipo de expertos trabajan incansablemente para
                  brindar soluciones de vanguardia y mantener los datos seguros
                  en todo momento.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
