// src/components/FloatingButtons/index.tsx
import React, { useState, useRef } from 'react';
import styles from './FloatingButtons.module.css';

// Importa tus imágenes
import LogoX from "../../assets/FloatingButtons/x_white-8 1.svg";
import LogoInstagram from "../../assets/FloatingButtons/instagram_white-8 1.svg";
import LogoLinkedin from "../../assets/FloatingButtons/instagram_white_1-8 1.svg";
import LogoFacebook from "../../assets/FloatingButtons/face_white-8 1.svg";

const FloatingButtons = () => {
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ top: 500 }); // Posición inicial
  const ref = useRef<number | null>(null);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setDragging(true);
    ref.current = e.clientY; // Captura la posición inicial del cursor
  };

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (dragging && ref.current !== null) {
      const currentTop = position.top + (e.clientY - ref.current);
      setPosition({ top: currentTop });
      ref.current = e.clientY;
    }
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  return (
    <div
      className={styles.container}
      style={{ top: `${position.top}px`, position: 'fixed', right: '20px', cursor: 'pointer', zIndex: '100' }}
      onMouseDown={onMouseDown}
      onMouseMove={dragging ? onMouseMove : undefined}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseUp} // Asegura que el arrastre se detenga si el cursor sale del componente
    >
      <a className={styles.buttonIcon} href="https://x.com/datasafe_us?s=21&t=dMFJ_ZGr2YBwiF3T-luSiQ" target="_blank" rel="noopener noreferrer">
        <img src={LogoX} alt="X" />
      </a>
      <a className={styles.buttonIcon} href="https://www.instagram.com/datasafe_us?igsh=Ymo4cjlwNHJpOHh1" target="_blank" rel="noopener noreferrer">
        <img src={LogoInstagram} alt="Instagram" />
      </a>
      <a className={styles.buttonIcon} href="https://www.linkedin.com/company/datasafeus" target="_blank" rel="noopener noreferrer">
        <img src={LogoLinkedin} alt="LinkedIn" />
      </a>
      <a className={styles.buttonIcon} href="https://www.facebook.com/share/R5CeuCSfg6YzKyof/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
        <img src={LogoFacebook} alt="Facebook" />
      </a>
    </div>
  );
};

export default FloatingButtons;
