import React, { useState } from "react";
import styles from "./ServiceSection.module.css";
import arrowDown from "../../assets/imageService/down-8.png";
import arrowUp from "../../assets/imageService/up-8.png";

// Definición de las propiedades que espera el componente
interface Service {
  title: string;
  description: string;
  imageUrl: any;
}

interface ServiceDisplayProps {
  services: Service[];
  mainTitle: string;
  backgroundColor: string; // Propiedad para el color de fondo
  id: string; // Propiedad para el identificador de la sección

}

const ServiceSection: React.FC<ServiceDisplayProps> = ({
  services,
  mainTitle,
  backgroundColor,
  id,
}) => {
  const [selectedService, setSelectedService] = useState(0);

  const changeService = (offset: number) => {
    const newServiceIndex =
      (selectedService + offset + services.length) % services.length;
    setSelectedService(newServiceIndex);
  };

  return (
    <div id={id} className={styles.serviceDisplay}>
      <div
            className={styles.containerContentPage}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection:'column',
              justifyContent: "space-between",
              marginTop: '20px'
            }}
          >
      <h1 className={styles.mainTitle}>{mainTitle}</h1>

      <section className={styles.contentCard} style={{ backgroundColor }}>
        <div className={styles.serviceList}>
          <div className={styles.serviceListContent}>
            {services.map((service, index) => (
              <div
                key={index}
                className={`${styles.serviceTitle} ${
                  index === selectedService ? styles.active : ""
                }`}
                onClick={() => setSelectedService(index)}
              >
                {service.title}
              </div>
            ))}
          </div>
          <div className={styles.navigationButtons}>
            <button
              className={styles.navigationButton}
              onClick={() => changeService(-1)}
            >
              <img src={arrowUp} alt="Up" />
            </button>
            <button
              className={styles.navigationButton}
              onClick={() => changeService(1)}
            >
              <img src={arrowDown} alt="Down" />
            </button>
          </div>
        </div>

        <div className={styles.serviceDetails}>
            <div className={styles.detailsContainer}>
              <div className={styles.detailsContent}>
                <div
                  className={styles.serviceDescription}
                  dangerouslySetInnerHTML={{
                    __html: services[selectedService].description,
                  }}
                />
                {/* <button className={styles.buttonSecondary}>Botón 2</button> */}
              </div>

              {services[selectedService].imageUrl !== null && (
                <img
                  src={services[selectedService].imageUrl}
                  alt={services[selectedService].title}
                  className={styles.serviceImage}
                />
              )}
            </div>
          </div>
      </section>
    </div>
    </div>
  );
};

export default ServiceSection;
