// src/pages/Home/index.tsx
import React from "react";
import styles from "./DescriptionService.module.css";
import backgroundImage from "../../assets/imageService/Rectangle-13.png";
import slider81 from "../../assets/imageContact/slider-8-1.png";
import { Helmet } from "react-helmet-async"; // Importa Helmet para gestionar los meta tags
import { Link } from "react-router-dom";

const DescriptionService: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Nuestra Experiencia | DATASAFE</title>
        <meta
          name="description"
          content="Descubre nuestra experiencia y profesionalismo en seguridad de la información, ciberseguridad y protección de datos. Confía en DATASAFE para proteger tu empresa con soluciones expertas y comprobadas."
        />
        <link
          rel="canonical"
          href="https://datasafesas.com/DescriptionService"
        />
        <meta
          name="keywords"
          content="seguridad de la información, ciberseguridad, protección de datos, ISO 27001, auditoría de seguridad"
        />
        <meta name="author" content="DATASAFE" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Añade más meta etiquetas según sea necesario */}
      </Helmet>

      <div
        className={styles.carouselContainer}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>

      <div className={styles.highlightSection}>
        <div className={styles.containerContentPage}>
          <h2 className={styles.highlightTitle}>
            Nuestra Experiencia y Profesionalismo{" "}
          </h2>
          <div className={styles.highlightContent}>
            <div className={styles.highlightDescription}>
              En DATASAFE, contamos con un equipo de profesionales altamente
              capacitados en seguridad de la información, seguridad informática,
              ciberseguridad y protección de datos. Nuestra amplia experiencia
              incluye la certificación de empresas bajo la norma ISO 27001 y el
              apoyo a organizaciones en el cumplimiento de normativas de
              seguridad. Además, nuestro equipo incluye auditores certificados
              por ICONTEC, garantizando el más alto nivel de calidad y confianza
              en nuestros servicios. Confía en nosotros para proteger tu empresa
              con soluciones expertas y comprobadas.
            </div>
            <div className={styles.highlightContentText}>
              <img
                src={slider81}
                alt="Highlight"
                className={styles.highlightImage}
              />
              <div className={styles.carouselButtons}>
                <Link to="/Contact">
                  <button className={styles.buttonSecondary}>
                    Organigrama
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionService;
