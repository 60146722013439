// src/pages/Home/index.tsx
import React, { useEffect } from "react";
import Carousel from "../../components/Carousel/carousel";
import Section from "../../components/Section/section";
import styles from "./home.module.css";
import Ellipse16 from "../../assets/ImageHome/Ellipse16.png";
import Ellipse17 from "../../assets/ImageHome/Ellipse17.png";
import Ellipse18 from "../../assets/ImageHome/Ellipse18.png";
import sideImage from "../../assets/ImageHome/sergio-8 1.png";
import PoliticaProteccionDatos from "../../assets/documents/Politica_protección_de_datos_DATASAFE.pdf";
import PoliticaSeguridadInformacion from "../../assets/documents/Politica_de_Seguridad_de_la_información_DATASAFE.pdf";
import PortafolioServicios from "../../assets/documents/PortafolioLiveDataSafe.pdf";
import PreguntasFrecuentes from "../../assets/documents/Preguntas_frecuentes_pagina_web_DATASAFE.pdf";
import TerminosCondiciones from "../../assets/documents/Aviso_Legal_y__terminos_de_uso_DATASAFE.pdf";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async"; // Importa Helmet

// Data de las secciones
const sectionsData = [
  {
    title: "GOBIERNO SGSI",
    imageSrc: Ellipse16,
    link: "/Service#gobierno-sgsi",
  },
  {
    title: "CIBERSEGURIDAD",
    imageSrc: Ellipse17,
    link: "/Service#ciberseguridad",
  },
  {
    title: "PROTECCION DE DATOS",
    imageSrc: Ellipse18,
    link: "/Service#firewalls",
  },
];

type TermItem =
  | { name: string; file: string }
  | { name: string; link: string }
  | { name: string };

const termsItems: { title: string; items: TermItem[] }[] = [
  {
    title: "Nuestras Políticas",
    items: [
      { name: "Política de Protección de Datos", file: PoliticaProteccionDatos },
      { name: "Política de Seguridad de la Información", file: PoliticaSeguridadInformacion },
    ],
  },
  {
    title: "De interés",
    items: [
      { name: "Portafolio de Servicios", file: PortafolioServicios },
      { name: "Preguntas Frecuentes", file: PreguntasFrecuentes },
      { name: "Términos y condiciones", file: TerminosCondiciones },
      { name: "Contáctanos", link: "/Contact" },
    ],
  },
  {
    title: "Destacados",
    items: [
      { name: "Formulario de petición de protección de datos personales", link: "https://forms.gle/inbWQgVNKaY9srJU9" },
      { name: "Correo Institucional", link: 'https://chat.google.com/dm/-jagtEAAAAE/joyP_-bYqGo/joyP_-bYqGo?cls=10' },
    ],
  },
];


const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Inicio | DATASAFE</title>
        <meta
          name="description"
          content="Bienvenido a la página de inicio de nuestro sitio web, donde ofrecemos los mejores servicios en gobierno SGSI, ciberseguridad y protección de datos."
        />
        <link rel="canonical" href="https://datasafesas.com" />
        <meta
          name="keywords"
          content="gobierno SGSI, ciberseguridad, protección de datos"
        />
        <meta name="author" content="DATASAFE" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Añade más meta etiquetas según sea necesario */}
      </Helmet>
      <Carousel />
      <div>
        <div
          style={{
            filter: "brightness(121%)",
            color: "rgb(172, 171, 171)",
            backgroundColor: "rgb(172, 171, 171)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.containerContentPage}>
            <div className={styles.highlightContentCard}>
              <h2 className={styles.highlightTitle}>Conoce nuestros servicios</h2>
            </div>
            <div className={styles.sectionsContainer}>
              {sectionsData.map((section, index) => (
                <Section
                  key={index}
                  title={section.title}
                  imageSrc={section.imageSrc}
                  link={section.link}
                />
              ))}
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "#d9d9d9", width: "100%" }}>
          <div className={styles.containerContentPage}>
            <div className={styles.highlightSection}>
              <img
                src={sideImage}
                alt="Highlight"
                className={styles.highlightImage}
              />
              <div className={styles.highlightContent}>
                <h2 className={styles.highlightTitles}>¿Cuál es tu necesidad?</h2>
                <p className={styles.highlight}>
                  ¡Solicite más información y asegure su futuro digital con nosotros!
                </p>
                <Link to="/Contact">
                  <button className={styles.highlightButton}>Contáctanos</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "rgb(172, 171, 171)", width: "100%" }}>
          <div className={styles.containerContentPage}>
            <div className={styles.termsSection}>
              {termsItems.map((item, itemIndex) => (
                <div key={itemIndex} className={styles.termsColumn}>
                  <h2 className={styles.termsTitle}>{item.title}</h2>
                  {item.items.map((termItem, termIndex) => (
                    <p key={termIndex} className={styles.termsItem}>
                      {'file' in termItem ? (
                        <a href={termItem.file} download className={styles.termsItem}>
                          {termItem.name}
                        </a>
                      ) : 'link' in termItem ? (
                        <a href={termItem.link} target="_blank" className={styles.termsItem} rel="noreferrer">
                          {termItem.name}
                        </a>
                      ) : (
                        termItem.name
                      )}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
