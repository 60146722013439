// src/components/Section/index.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './section.module.css';

type SectionProps = {
  title: string;
  imageSrc: string; // Asegúrate de pasar la ruta de la imagen correcta cuando utilices este componente
  link: string; // Añadido para manejar la redirección
};

const Section: React.FC<SectionProps> = ({ title, imageSrc, link }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <section className={styles.sectionContainer} onClick={handleClick} style={{ cursor: 'pointer' }}>
      <div className={styles.imageContainer}>
        <img src={imageSrc} alt={title} className={styles.sectionImage} />
      </div>
      <h2 className={styles.sectionTitle}>{title}</h2>
    </section>
  );
};

export default Section;
